<template>
  <div class="main-content">
    <header class="titles-header">
      <div class="titles-header__side">
        <h1 class="titles-header__title">{{$t('moreinfo.masinfo')}} </h1><br><br>
        <a href="https://meaning.cl/"  target="_blank" style="text-decoration:none"> <h4>www.meaning.cl</h4> </a>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'AdminHome',
  data () {
    return {}
  },
  methods: {}
}
</script>